import { isMobileScreenSize } from "../shared/utilities";

class MainMenu {
  protected mainMenuButton: Element;

  protected mobileMenu: Element;

  protected mobileMenuLinks: NodeListOf<Element>;

  protected siteOverlay: Element;

  protected navLinkPromoted: HTMLElement;

  protected mobileNavLinkPromoted: HTMLElement;

  constructor() {
    /* eslint-disable prefer-destructuring */
    this.mainMenuButton = document.querySelectorAll(".js-main-menu-button")[0];
    this.mobileMenu = document.querySelectorAll(".mobile-menu__wrapper")[0];
    this.mobileMenuLinks = document.querySelectorAll(".mobile-menu__link");
    this.siteOverlay = document.querySelectorAll("#site-overlay")[0];
    this.navLinkPromoted =
      document.querySelectorAll<HTMLElement>(".nav-link.promoted")[0];
    this.mobileNavLinkPromoted = document.querySelectorAll<HTMLElement>(
      ".mobile-menu__link.promoted",
    )[0];
    /* eslint-enable */
  }

  actions() {
    if (!this.mainMenuButton) {
      return;
    }
    this.mainMenuButton.addEventListener("click", (e) => this.openMenu(e));

    this.mobileMenu.querySelectorAll(".js-mobile-menu-link").forEach((link) => {
      link.addEventListener("click", (e) => {
        const target = e.currentTarget as Element;
        const activeSubMenu = target.nextElementSibling;
        const hasSubMenu = !!activeSubMenu;

        if (hasSubMenu) {
          e.preventDefault();
          this.mobileMenu.classList.add("mobile-menu__wrapper_sidebar-opened");
          activeSubMenu?.classList.add("mobile-menu__submenu_opened");
        }
      });
    });

    this.mobileMenu
      .querySelectorAll(".js-mobile-menu-back")[0]
      .addEventListener("click", (e) => {
        e.preventDefault();
        this.mobileMenu.classList.remove("mobile-menu__wrapper_sidebar-opened");
        this.mobileMenu
          .querySelectorAll(".mobile-menu__submenu_opened")[0]
          .classList.remove("mobile-menu__submenu_opened");
      });

    this.mobileMenu
      .querySelectorAll(".js-mobile-menu-close")[0]
      .addEventListener("click", (e) => this.closeMenu(e));
  }

  openMenu(e: Event) {
    e.preventDefault();
    this.mobileMenu.classList.add("mobile-menu__wrapper_opened");
    this.siteOverlay.classList.add("show");
    this.addOverlayEventListener();
  }

  closeMenu(e: Event) {
    e.preventDefault();
    this.mobileMenu.classList.remove("mobile-menu__wrapper_opened");
    this.siteOverlay.classList.remove("show");
    this.removeOverlayEventListener();
  }

  addOverlayEventListener() {
    this.siteOverlay.addEventListener("click", (e) => this.closeMenu(e));
  }

  removeOverlayEventListener() {
    this.siteOverlay.removeEventListener("click", (e) => this.closeMenu(e));
  }

  /**
   * Only used on Edit Profile
   */
  openSubMenu() {
    const openMenuButton = document.querySelectorAll("[data-open-sub-menu]");

    openMenuButton.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        const target = e.currentTarget as Element;
        const mobileLinks = this.mobileMenu.querySelectorAll<HTMLAnchorElement>(
          ".mobile-menu > .mobile-menu__item > .mobile-menu__link",
        );
        const searchText = target.getAttribute("data-open-sub-menu");
        const found = [...mobileLinks].find(
          (l) => l.text.trim() === searchText,
        );

        const clickEvent = new Event("click");
        found?.dispatchEvent(clickEvent);
        this.mainMenuButton.dispatchEvent(clickEvent);
      });
    });
  }

  translatePromotedNavLinks() {
    const dataValue = window.gettext("New");

    if (this.navLinkPromoted) {
      this.navLinkPromoted.dataset.value = dataValue;
    }

    if (this.mobileNavLinkPromoted) {
      this.mobileNavLinkPromoted.dataset.value = dataValue;
    }
  }

  disableMobileMenuTabbing() {
    /**
     * Tabbing through mobile menu on desktop causes tabbed elements to
     * 'disappear' since the mobile menu is in the DOM but hidden.
     */
    if (!isMobileScreenSize()) {
      for (let i = 0; i < this.mobileMenuLinks.length; i += 1) {
        this.mobileMenuLinks[i].setAttribute("tabindex", "-1");
      }
      this.mobileMenu
        .querySelector(".mobile-menu__btn-close")
        ?.setAttribute("tabindex", "-1");
    }
  }

  init() {
    this.actions();
    this.openSubMenu();
    this.translatePromotedNavLinks();
    this.disableMobileMenuTabbing();
  }
}

const Script = new MainMenu();
Script.init();
